import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"


import LuteinComplexMobile from "./../../images/technology-mobile.jpg"
import LuteinComplexTablet from "./../../images/technology-tablet.jpg"
import LuteinComplexDesktop from "./../../images/technology-desktop.jpg"
import LuteinComplexXL from "./../../images/technology-xl.jpg"
import Feature1 from "./../../images/flower-liquid-caps.jpg"
import Feature2 from "./../../images/LICAPS-GRAPH-EN.svg"
import Feature3 from "./../../images/production-en.svg"
import Feature4 from "./../../images/rila-purity.jpg"
import Feature5 from "./../../images/safety-en.svg"
import Feature6 from "./../../images/sealing-en.svg"


const TechnologyPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "TechnologyEn" }}>
    <Seo title="LICAPS&trade; - next generation capsules" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>TECHNOLOGY</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Licaps"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h1 className="display-4 font-light">
            Next
            <br />
            generation
            <br />
            technology
          </h1>
        </Col>
      </Row>

      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Next generation
            <br /> technology
          </h1>
        </Col>
        <Col sm={12} className="mb-4">
          <h5 className="subhead font-light">
            Our products - Lutein Complex Plus, Hepatofelin and Stavien, are
            manufactured in France by CAPSUGEL FRANCE SAS&trade; using the
            latest technology for the production of hard gelatin capsules with
            liquid contents - LICAPS&trade;.
          </h5>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Capsules with liquid contents?</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={{ span: 8, offset: 2 }} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Capsules with liquid contents?"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col sm={12} className="order-md-0">
          <p>
            Licaps™ technology eliminates thermal treatment of the active
            substance, which allows it to preserve its beneficial properties and
            guarantees quick and complete absorption by the body. Compared to
            the hard forms, such as tablets, the ingredients in Licaps™ are
            released very quickly, because they are dissolved in advance.
          </p>
          <p>
            The technology was developed and patented by CAPSUGEL FRANCE SAS,
            which produces our products with compliance to GMP – Good
            Manufacturing Practices, and this guarantees the quality of every
            single product.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            Better efficiency and faster solubility
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col sm={12} md={{ span: 10, offset: 1 }} className="order-md-1">
          <img
            srcSet={Feature2}
            alt="Better efficiency and faster solubility"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col sm={12} className="order-md-0">
          <p>
            Scientific studies show that the liquid formulas offer improved
            absorption.*
          </p>
          <p>
            Licaps™ are capsules with liquid content, made using the CAPSUGEL
            FRANCE SAS patented technology for putting liquid herbal extracts
            and other liquid formulas into hard capsules. This process
            eliminates the thermal treatment of the ingredients. This allows
            preserving their beneficial properties to the maximum extent and
            guarantees their quick and complete absorption.
          </p>
          <p>
            Compared to the tablets and other similar methods of delivery, where
            the substance is in a solid form, the ingredients in Licaps™ are
            released very quickly as they are in advance dissolved. The capsules
            themselves get completely dissolved after taking in.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Production</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={5} className="order-sm-1">
          <img
            srcSet={Feature3}
            alt="Production"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={7} className="order-sm-0">
          <p>
            Licaps™ uses a patented technology LEMS to seal liquid into the
            capsule. Thus a reliable sealing is achieved, which helps to avoid
            leakage of the liquid content and prevents the ingredients from
            oxidation and moisture.
          </p>
          <p>
            The capsules contain a nitrogen bubble, as a result of sealing in
            nitrogen environment, which assures keeping the product fresh. The
            nitrogen bubble increases the stability of the content and vastly
            reduces formation of peroxides and reactive oxygen.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            Purity and naturalness of the capsules and content
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Purity and naturalness of the capsules and content"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={6}>
          <p>
            Licaps™ capsules protect closed ingredients from oxygen and external
            influence, which doesn’t allow oxidation, quality deterioration and
            side interactions of ingredients.
          </p>
          <p>
            On the other hand, the capsules cannot be opened and closed again
            after the sealing had already been damaged. Compared to tablets,
            Licaps™ contain much less additives. Licaps™ capsules meet not only
            all the requirements of natural and clean products, but also some
            cultural and religious preferences, such as vegetarianism, Halal,
            Kosher etc. Licaps™ capsules are odorless and tasteless, and don’t
            irritate the gastrointestinal tract.
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <ul>
            <li>Solvent free</li>
            <li>Preservatives free</li>
            <li>Gluten free</li>
            <li>Starch free</li>
            <li>Sugar free</li>
          </ul>
        </Col>
        <Col md={6}>
          <ul>
            <li>Fewer excipients</li>
            <li>No plasticizers</li>
            <li>Allergen free</li>
            <li>GMO free</li>
          </ul>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Safety and quality guarantee</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={5} className="order-md-1">
          <img
            srcSet={Feature5}
            alt="Safety and quality guarantee"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={7} className="order-md-0">
          <p>
            Licaps™ protects the substances in them from oxygen and external
            influences, and thus reduce the oxidation, degradation, hydrolysis
            and side reactions of the substances. On the other hand, once opened
            Licaps™ capsule can in no way be closed again, which guarantees a
            protected, clean and safe product.
          </p>
          <p>
            <a href="https://vitaherb.bg/leaflets/Lutein-Complex-Plus-Quality-Certificate.pdf">
              Quality certificate Lutein Complex
            </a>
            <br />
            <a href="https://vitaherb.bg/leaflets/Hepatofelin-Quality-Certificate.pdf">
              Quality certificate Hepatofelin
            </a>
            <br />
            <a href="https://vitaherb.bg/leaflets/Stavien-Quality-Certificate.pdf">
              Quality certificate Stavien
            </a>
          </p>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">LEMS sealing technology</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature6}
            alt="LEMS sealing technology"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={6}>
          <p>
            The exclusive for CAPSUGEL FRANCE SAS technology LEMS (Liquid
            Encapsulation Microspray Sealing), provides a safe sealing for the
            capsules and reduces the possibility of content running out of it.
          </p>
          <p>
            Licaps™ are closed in a way which creates a barrier against oxygen
            and water, and protects the content of the capsules. The capsules
            are sealed in a nitrogen environment, which avoids oxidation of the
            content, saves products’ freshness and increases the expiry period.
          </p>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Sources</h5>
        </Col>
      </Row>
      <Row className="mb-4 product-container">
        <Col className="h6-small">
          <p>
            * N. Madit*, D. Cade and H. Benameur, Comparative bioavailability of
            CoQ 10 formulations: Licaps™ formulation versus current commercial
            formulations. Development Center, Research and Development. CAPSUGEL
            FRANCE SAS, 10 rue Timken Colmar F68027 (2006)
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default TechnologyPageEn
